/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // JavaScript to be fired on all pages
        $(document).ready(function(){

          //window width listener
          var windowWidth = $(window).width() + 15;

          $(window).resize(function() {

            windowWidth = $(window).width() + 15;

            if( windowWidth > 1039 || windowWidth < 971 ){
              $('.tablet-toggle').hide();
              $("#cp-nav-primary .menu-primary-menu-container .nav > li").slice(-3, -1).show();
            }else{
              $('.tablet-toggle').show();
              $('.nav-primary').show();
              $("#cp-nav-primary .menu-primary-menu-container .nav > li").slice(-3, -1).hide(); // hide case studies and about tab
              $('.tablet-toggle-close').hide();
              $('.tablet-menu').hide();
            }

            if(windowWidth < 971){
              $('.nav-primary').hide();
              $('.header-icons').hide();

              if(!$("#cp-nav-primary .container-top").is(":visible")){
                $("#cp-nav-primary .container-top").show();
              }

              if($("#cp-nav-primary .container-bottom .brand").is(":visible")){
                $("#cp-nav-primary .container-bottom .brand").hide();
              }

              $("#cp-nav-primary .mobile-toggle").show();
              $("#cp-nav-primary .mobile-toggle-close").hide();

              $("#cp-nav-primary .tablet-toggle-close").hide();
              $("#cp-nav-primary .tablet-menu").hide();

            }

            if(windowWidth > 970){
              $('.header-icons').show();

              if(!$("#cp-nav-primary .container-bottom .brand").is(":visible")){
                $("#cp-nav-primary .container-bottom .brand").not(".stickied-nav-only").show();
              }

              $("#cp-nav-primary").removeClass("mobile-nav-open");
              $("body").removeClass("noscroll");

            }

            if(windowWidth > 1039){

              $('.nav li.menu-item-has-children').each(function(){

                var submenu = $(this).children('.submenu-wrap');

                var submenuCount = $(this).find('.submenu-first').children('.menu-item-has-children').length;

                /*
                 Split the submenu columns
                 */
                switch(submenuCount){
                  case 1:
                    submenu.css('min-width', '0px');
                    break;
                  case 2:
                    submenu.css('min-width', '415px');
                    break;
                  case 3:
                    submenu.css('min-width', '515px');
                    break;
                  case 4:
                    submenu.css('min-width', '615px');
                    break;
                }

              });

            }

          });

          /*
          Search Bar
           */
          $('.search-icon').click(  function(){

            //focus search text
            setTimeout(function(){
              $('#header-search').focus();
            });


            //search fields css
            if( windowWidth > 970 ){
              //$('.cp-search-icon').css('margin-top', '-4px');
            }

            $('.search-bar').css('padding', '3px 1px 1px 1px');
            //$('.search-bar').css('width', '100%');

            if( !$(this).hasClass('search-active') ){

              $(this).addClass('search-active');

              $('.search-bar').css({
                'border': '1px solid #959595',
                'opacity': '0'
              });

              if( windowWidth < 970 ){

                $('.search-bar').css('width', '100%');

                $(".header-icons-inner").css("margin-left", "-10px");

                //$('.mobile-only').css('float','none');
                //$('.mobile-only').css('margin-top','5px');

                setTimeout(function(){
                  $('.search-bar td.mobile').css('display', 'table-cell');
                }, 200);

              }else{

                $('.search-bar').css('margin-top', '-5px');
                $('.search-bar td').css('display', 'table-cell');

              }

              $('.search-bar').animate({
                opacity: 1
              }, 200, function(){

              });
            }else{
              $('.search-bar').css({
                'margin-top': '',
                'padding': '0',
                'border': 'none'
              });
              $(this).removeClass('search-active');
              $('.search-bar td').css('display', 'none');
              $('.search-bar td.show').css('display', 'table-cell');

              if( windowWidth < 970 ){
                $('.search-bar').css('width', '');
                $(".header-icons-inner").css("margin-left", "");
              }
            }
          });

          /*
          Disable search button if field empty
           */
          $('.search-submit').attr('disabled', 'true');
          $("#header-search").keyup(function (e) {
            var inputVal = $(this).val();
            if( inputVal ){
              $('.search-submit').removeAttr('disabled');
            }else{
              $('.search-submit').attr('disabled', 'true');
            }
          });

          /*
           Nav Menu - Mobile
           */
          $('.mobile-toggle').click(  function(){

            $('.nav-primary, .header-icons, .mobile-toggle-close').show();
            $(this).hide();

            $("#cp-nav-primary").addClass("mobile-nav-open");
            $("body").addClass("noscroll");

          });

          $('.mobile-toggle-close').click(  function(){
            $(this).hide();
            $('.submenu-wrap').hide();
            $('.nav li.menu-item-has-children a').attr('data-visible', 'hidden');
            $('.mobile-toggle').show();
            $('.nav-primary, .header-icons').hide();

            $("#cp-nav-primary").removeClass("mobile-nav-open");
            $("body").removeClass("noscroll");

          });

          $('.tablet-toggle').click(  function(evt){
            evt.stopPropagation();
            $(this).hide();
            $('.tablet-toggle-close').show();
            $('.tablet-menu').show();

            $('.submenu-wrap').hide();
          });

          $('.tablet-toggle-close').click(  function(){
            $(this).hide();
            $('.tablet-toggle').show();
            $('.tablet-menu').hide();
          });


          $("html").click(function(){
            $('.submenu-wrap').hide();

            if($(".tablet-toggle-close").is(":visible")){
              $(".tablet-toggle-close").hide();
              $(".tablet-toggle").show();
              $(".tablet-menu").hide();
            }

          });

          /*
           Nav Menu - Mobile
           */

          if(windowWidth > 970 && windowWidth <= 1039){
            //console.log($("#cp-nav-primary .menu-primary-menu-container .nav > li"));
            //console.log($("#cp-nav-primary .menu-primary-menu-container .nav > li").slice(-3, -1));
            $("#cp-nav-primary .menu-primary-menu-container .nav > li").slice(-3, -1).hide();
          }

          /*
          Nav Menu - Desktop
           */

          //disable top links
          $('#cp-nav-primary .nav .menu-item a').click(function(e){
            e.preventDefault();
            e.stopPropagation();
            if(!$(this).parent().find(".submenu-wrap").is(":visible")){
              $("#cp-nav-primary .nav .menu-item .submenu-wrap").hide();
            }
            $(this).parent().find(".submenu-wrap").toggle();

            if($(".tablet-toggle-close").is(":visible")){
              $(".tablet-toggle-close").hide();
              $(".tablet-toggle").show();
              $(".tablet-menu").hide();
            }

          });

          //re-enable other menu links
          $('#cp-nav-primary .submenu-first a').unbind('click');
          $('#cp-nav-primary .contact-button a').unbind('click');

          //active parent highlighting
          $('.active-page').closest('.nav').find('.current-menu-ancestor').children('a').css('color', '#cc212c');

          //fixed header
          var faded = false;
          $(window).scroll(function() {

            if(windowWidth <= 970){
              return;
            }

            var height = $(window).scrollTop();

            if(height  > 133){

              $('#cp-nav-primary').css('position', 'fixed');

              if(!faded){
                $('#cp-nav-primary').css('opacity', '0');
                $('#cp-nav-primary').animate({
                  opacity: 1
                }, 500, function(){});
                faded = true;
              }

              if( windowWidth > 970 ){
                $('#cp-nav-primary').css('padding-top', '15px');
                $('#cp-site-wrap').css('padding-top', '135.2px' );
                $('#cp-nav-primary').find('.container-top').hide();
                $("#cp-nav-primary").find(".brand").hide().filter(".stickied-nav-only").show();
              }
            }else{
              $('#cp-nav-primary').css('position', 'relative');
              if( windowWidth > 970 ){
                $('#cp-nav-primary').css('padding-top', '0px');
                $('#cp-site-wrap').css('padding-top', '0px' );
                $('#cp-nav-primary').find('.container-top').show();
                $("#cp-nav-primary").find(".brand").hide().not(".stickied-nav-only").show();
              }
              faded = false;
            }
          });


          //submenu columns etc.
          if( windowWidth > 1040 ){

            $('.nav li.menu-item-has-children').hover( function () {

              var submenu = $(this).children('.submenu-wrap');

              $(this).find('a').first().css('border-bottom', '4px solid #cc212c');

              var submenuCount = $(this).find('.submenu-first').children('.menu-item-has-children').length;

              /*
               Split the submenu columns
               */
              switch( submenuCount ){
                case 1:
                  submenu.css('min-width', '0px');
                  break;
                case 2:
                  submenu.css('min-width', '415px');
                  break;
                case 3:
                  submenu.css('min-width', '515px');
                  break;
                case 4:
                      submenu.css('min-width', '615px');
                  break;
              }

            }, function () {

              $(this).find('a').first().css('border-bottom', '4px solid transparent');

            });
          }else{
            //mobile parent-child menu toggling
            $('.nav li.menu-item-has-children a').click(  function( e ) {
              e.preventDefault();
              var visible = $(this).attr('data-visible');
              if( visible === 'visible' ){
                $('.submenu-wrap').hide();
                $(this).attr('data-visible', 'hidden');
              }else{
                $(this).attr('data-visible', 'visible');
                $('.nav li.menu-item-has-children li a').unbind('click');
                $('.submenu-wrap').hide();
                $(this).closest('li').find('.submenu-wrap').show();
              }
            });
          }



          /*
           Lightbox & Gallery
           */

          $('a.gallery').featherlightGallery({
            gallery: {
              previous: function(){
                $('video').hide();
              },
              next:  function(){
                $('video').hide();
              },
              fadeIn: 300
            },
            openSpeed: 300
          });


          //append lightbox content
          $.featherlightGallery.prototype.afterContent = function(){

            //newsfeed article headlines/category
            if( this.$currentTarget.find('img').hasClass('cp-article') ){
              var articleContent = this.$currentTarget.find('img').attr('data-headline');
              var articleCategory = this.$currentTarget.find('img').attr('data-category');
              var captionText = '<div class="caption"><span class="category">' + articleCategory + '</span>' + articleContent + '</div>';
              this.$instance.find('.caption').remove();
              this.$instance.find('.featherlight-content').append( captionText );
            }

            //newsfeed image caption
            if( this.$currentTarget.find('img').hasClass('cp-image') ){
              var content = this.$currentTarget.find('img').attr('alt');
              var category = this.$currentTarget.find('img').attr('data-category');
              var photoCaption = '<div class="caption"><span class="category">' + category + '</span>' + content + '</div>';
              this.$instance.find('.caption').remove();
              this.$instance.find('.featherlight-content').append( photoCaption );
            }

            //audio overlay
            if( this.$currentTarget.find('img').hasClass('cp-audio') ){
              $('.featherlight-image').hide();
              var audio = this.$currentTarget.find('img').data('audio');
              var audioContent = this.$currentTarget.find('img').data('content');
              var audioCategory = this.$currentTarget.find('img').data('category');

              this.$instance.find('.caption').remove();

              var audioCaptionHTML = '<div class="caption video-caption audio-caption"><audio controls autoplay class="cp-video-lightbox cp-audio-lightbox"><source src="'+ audio + '" type="audio/mpeg"></audio><span>' + audioCategory + '</span><br> ' + audioContent + '</div>';

              this.$instance.find('.featherlight-content').append( audioCaptionHTML );
            }

            //video gallery
            if( this.$currentTarget.find('img').hasClass('cp-video') ){
              $('.featherlight-content').addClass('hide-image');
              var videoPath = this.$currentTarget.find('img').attr('data-video-path');
              var cpVideoDesc = this.$currentTarget.find('img').attr('data-description');
              var cpVideoTitle = this.$currentTarget.find('img').attr('data-title');
              this.$instance.find('.caption').remove();

              var videoCaptionHTML = '<div class="caption video-caption"><video controls autoplay class="cp-video-lightbox"><source src="'+ videoPath + '" type="video/mp4"></video><span>' + cpVideoTitle + '</span><br> ' + cpVideoDesc + '</div>';

              this.$instance.find('.featherlight-content').append( videoCaptionHTML );
            }

        };

          /*
          Message Form Toggle
           */
          $('.message-field-toggle').click(function(){
            $(this).find('textarea').show();
          });

          /*
          Testimonial Widget
           */
          $('.testimonial-thumb-img').click(  function(){
            $('.testimonial-thumb-img').removeClass('active');
            $('.testimonial-hero').fadeOut(300);
            $(this).addClass('active');
            var postID = $(this).attr('data-post');
            $(this).closest('.testimonials-widget-inner').find('.testimonial-hero[data-post="' + postID +'"]').delay(300).fadeIn(300);
          });

          /*
          Lightbox Icons
           */
          $('.featherlight-content').find('.featherlight-previous').find('span').html(
              '<i class="fa fa-angle-left"></i>'
          );
          $('.featherlight-content').find('.featherlight-next').find('span').html(
              '<i class="fa fa-angle-right"></i>'
          );

          /*
          BB Gallery & Tiles - stuttered fadeIn animation
           */
          $('.fl-module-gallery.cp-animate img').css('opacity', '0');
          $('.cp-animate .cp-newsfeed-widget .tile-item').css('opacity', '0');

          function cp_gallery_fade( className ){
            $.each($( className ), function(i, el){
              $(el).css({'opacity':0});
              setTimeout(function(){
                $(el).animate({
                  'opacity':1.0
                }, 450);
              },200 + ( i * 200 ));

            });
          }

          $.fn.isOnScreen = function(){

            var win = $(window);

            var viewport = {
              top : win.scrollTop(),
              left : win.scrollLeft()
            };
            viewport.right = viewport.left + win.width();
            viewport.bottom = viewport.top + win.height();

            var bounds = this.offset();

            if( bounds ){
              bounds.right = bounds.left + this.outerWidth();
              bounds.bottom = bounds.top + this.outerHeight();

              return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
            }

            return false;

          };

          var galleryDone = 0;
          var tileDone = 0;

          $(window).scroll(function(){
            if($('.fl-module-gallery.cp-animate').isOnScreen()){
              //gallery
              if( galleryDone === 0 ){
                cp_gallery_fade( '.fl-module-gallery.cp-animate img' );
                galleryDone = 1;
              }
            }
            //newsfeed widget
            if($('.cp-animate .cp-newsfeed-widget').isOnScreen()){
              if( tileDone === 0 ){
                cp_gallery_fade( '.cp-animate .cp-newsfeed-widget .tile-item' );
                tileDone = 1;
              }
            }
          });

          /*
          Modernizr
           */
          if ( Modernizr.objectfit ) {
            $('.cp-insta-image').each(function () {
              $(this).addClass('cp-obj-fit');
            });
          }

          /*
          Pricing Table Scroll to Form
           */
          $('.fl-pricing-table-inner-wrap').find('.fl-button').click(function( e ){
            e.preventDefault();
            $('html, body').animate({
              scrollTop: $('.fl-widget').find('form').offset().top - 80
            }, 500);
          });

          /*
          Book Tabs
           */

          function show_product_tab( el ){
            var id = $(el).attr('id');
            $('.tab-label').removeClass('active');
            $('.tab-item').hide();
            $(el).addClass('active');
            $('.' + id).show();
            return id;
          }

          $('.tab-label').click(function(){

            //mobile
            if( windowWidth < 970 ){

              if( $(this).hasClass('active')){
                $('.tab-label').show();
              }else{
                show_product_tab( this );
                $('.tab-label').hide();
                $(this).show();
              }

            }else{
              show_product_tab( this );
            }

          });

          /*
          Gforms scroll correct
           */
          $('.gform_validation_error').find('#gf_1');


          /*
          Widget RSS
           */
          $('.widget_rss ul li a').attr('target', '_blank');


        });

        /*
         Grid Gallery (Masonry)
         */
        var $grid = $('.cp-newsfeed-widget').masonry({
          columnWidth: '.tile-item',
          itemSelector: '.tile-item',
          percentPosition: true
        });
        // layout Masonry after each image loads
        $grid.imagesLoaded().progress( function() {
          $grid.masonry('layout');
        });

        /*
        Resize text input on type (package builder)
         */

        //var $inputs = $('.resizing-input');
        //
        //// Resize based on text if text.length > 0
        //// Otherwise resize based on the placeholder
        //function resizeForText(text) {
        //  var $this = $(this);
        //  if (!text.trim()) {
        //    text = $this.attr('placeholder').trim();
        //  }
        //  var $span = $this.parent().find('span');
        //  $span.text(text);
        //  var $inputSize = ( $span.width() * 1.2 ) + 'px !important;';
        //  $this.attr("style", "width: " + $inputSize );
        //}
        //
        //$inputs.find('input').keypress(function (e) {
        //  if (e.which && e.charCode) {
        //    var c = String.fromCharCode(e.keyCode | e.charCode);
        //    var $this = $(this);
        //    resizeForText.call($this, $this.val() + c);
        //  }
        //});
        //
        //// Backspace event only fires for keyup
        //$inputs.find('input').keyup(function (e) {
        //  if (e.keyCode === 8 || e.keyCode === 46) {
        //    resizeForText.call($(this), $(this).val());
        //  }
        //});
        //
        //$inputs.find('input').each(function () {
        //  var $this = $(this);
        //  resizeForText.call($this, $this.val())
        //});

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Start image fading effect for icons
        var speed = 150;
        var waypoint = new Waypoint({
            element: $('.icon-wrap').children().first(),
            handler: function(direction) {
              if(direction === "down"){
                $('.icon-wrap').children().each(function(k, v){
                    var el = this;

                    setTimeout(function (){
                      $(el).animate({
                    'opacity': '30'
                }, {
                    step: function (now, fx) {
                        $(el).css({"transform": "translate3d(0px, " + -now + "px, 0px)"});
                    },
                    duration: 500,
                    easing: 'linear',
                    queue: false,
                    complete: function () {
                    }
                }, 'linear');

                $(el).animate({ textIndent: 100 }, {
                    duration : 500,
                    easing: 'linear',
                    queue: false
                });
                    }, k*speed);

                });
              }

              if(direction === "up"){
                $('.icon-wrap').children().css({"opacity": "0"});
              }
            },  offset: '90%'
        });

        // Black text callout word rotater
        $("[data-typer-words]").each(function(){

          var typed = new Typed(this,{
            strings: $(this).data("typer-words").split(","),
            typeSpeed: 70,
            loop: true,
            showCursor: false,
            backDelay: 5000,
          });
        });

        /**
         * Partner logos carousel
         */
        var rotation_time = parseInt($('.partner-logos').attr('rotaion-time'));
        function start_partner_logos_carousel(i){
        		var delay = $('#logosCarousel_' + i.toString()).attr('delay');
            setTimeout(function(){
              $('#logosCarousel_' + i.toString()).carousel({
                   interval : rotation_time
               });
            }, delay);
        }
        for(var i = 0; i < $('.partner-logos').children().length; i++){
        	start_partner_logos_carousel(i);
        }

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
